import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import BankContent from 'components/directus/bank-content/BankContent';
import CallToAction from 'components/directus/call-to-action/CallToAction';
import PageTitle from 'components/directus/page-title/PageTitle';
import TrustPilotBanner from 'components/directus/trustpilot-banner/TrustPilotBanner';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import { getFieldBySlug } from 'utils/directus';
import { PageProps } from 'utils/gatsby.interface';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
    const slug = 'countingup';
    const { node } = getFieldBySlug(slug, data.allDirectusPage.edges);

    return (
        <>
            <Helmet>
                <title>{node.title}</title>
                <meta name="title" content={node.title} />
                <meta name="description" content={node.description} />
                <meta name="robots" content={node.robots} />
                <link rel="canonical" href={node.canonical} />
            </Helmet>

            <Wrapper>
                <Section bottom={0}>
                    <PageTitle slug={slug} />
                </Section>
                <Section>
                    <BankContent slug={slug} />
                </Section>
                <Section>
                    <TrustPilotBanner />
                </Section>
                <Section>
                    <CallToAction slug="company-registration" />
                </Section>
            </Wrapper>
        </>
    );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "countingup" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
